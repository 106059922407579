<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          商户信息
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"></span>
      </div>
      <div class="card-toolbar">
        <!-- <button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
          保存
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          取消
        </button> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Alert-->
        <div
          :class="['alert', 'alert-custom', 'fade', 'show', 'mb-10', { 'alert-light-success': alert.isSuccess == true }, { 'alert-light-danger': alert.isSuccess == false }]"
          role="alert" v-if="alert.show">
          <div class="alert-icon">
            <i class="icon-2l fas fa-check-circle"></i>
          </div>
          <div class="alert-text font-weight-bold">
            {{ alert.message }}
          </div>
          <div class="alert-close">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" v-on:click="alert.show = false">
              <span aria-hidden="true">
                <i class="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
        <!--end::Alert-->
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">账户信息:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">登录账号</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input readonly disabled class="form-control form-control-lg form-control-solid" type="text"
                placeholder="登录账号..." v-model="merchantInfo.loginAccount" />
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">商户名称</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input type="text" class="form-control form-control-lg form-control-solid"
                v-model="merchantInfo.merchantName" placeholder="公司名称..." />
            </div>
          </div>
        </div>

        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">联系电话</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input type="text" class="form-control form-control-lg form-control-solid" v-model="merchantInfo.telPhone"
                placeholder="联系电话..." />
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">电子邮箱</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input type="text" class="form-control form-control-lg form-control-solid" v-model="merchantInfo.email"
                placeholder="Email..." />
            </div>
          </div>
        </div>

        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5"></div>
        <!--begin::Form Group-->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">对接信息:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">商户ID</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input type="text" class="form-control form-control-lg form-control-solid" v-model="merchantInfo.merchantId"
                placeholder="商户ID..." />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">AppId</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input type="text" class="form-control form-control-lg form-control-solid" v-model="merchantInfo.appId"
                placeholder="AppId..." />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">客户端公钥</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <textarea rows="3" type="text" class="form-control form-control-lg form-control-solid" v-model="merchantInfo.publicKey"
                placeholder="客户端公钥..." />
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">客户端私钥</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <textarea rows="3" type="text" class="form-control form-control-lg form-control-solid"
                v-model="merchantInfo.privateKey" placeholder="客户端私钥..." />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label form-group-label">服务端公钥</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <textarea rows="3" type="text" class="form-control form-control-lg form-control-solid"
                v-model="merchantInfo.serverPublicKey" placeholder="服务端公钥..." />
            </div>
          </div>
        </div>

      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "CustomerInformation",
  data() {
    return {
      merchantInfo: {},
      alert: {
        show: false,
        message: "",
        isSuccess: false,
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // save() {
    //   let that = this;
    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_save_changes"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    //   ApiService.post("/api/edit/merchant", this.merchantInfo).then(({ data }) => {
    //     that.alert.show = true;
    //     if (data.success) {
    //       that.alert.isSuccess = true;
    //       that.alert.message = "保存成功!";
    //     } else {
    //       that.alert.message = data.message;
    //       that.alert.isSuccess = false;
    //     }
    //   }).catch(({ response }) => {
    //     console.info("error:", response);
    //     that.alert.show = true;
    //     that.alert.message = data.err;
    //     that.alert.isSuccess = false;
    //   });

    //   submitButton.classList.remove(
    //     "spinner",
    //     "spinner-light",
    //     "spinner-right"
    //   );

    //   // dummy delay
    //   setTimeout(() => {
    //     that.alert.show = false;
    //   }, 3000);
    // },
    cancel() {

    },
    getData() {
      ApiService.get("/api/profile/merchant").then(({ data }) => {
        if (data.success) {
          this.merchantInfo = data.result;
        }
      })
    }
  },
  computed: {}
};
</script>

<style scoped>
</style>
