<template>
  <div class="d-flex flex-row mt-4">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-8">
          <div class="d-flex justify-content-end"></div>
          <div class="d-flex align-items-center">
            <div class="
                symbol symbol-60 symbol-xxl-100
                mr-5
                align-self-start align-self-xxl-center
              ">
              <div class="symbol-label" :style="{ backgroundImage: `url(${avatar})` }"></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a class="
                  font-weight-bolder font-size-h5
                  text-dark-75 text-hover-primary
                ">用户名: {{ currentUserAccountInfo.userName }}</a>
              <div class="mt-2">
                <span class="
                    label label-xl label-inline label-light-primary
                    py-2
                    px-3 px-xxl-5
                    my-1
                  " v-if="currentUserAccountInfo.userType == 1">平台用户</span>
                <span class="
                    label label-xl label-inline label-light-primary
                    py-2
                    px-3 px-xxl-5
                    my-1
                  " v-if="currentUserAccountInfo.userType == 2">商户用户</span>
              </div>
            </div>
          </div>
          <!--end::User-->

          <!--begin::Contact-->
          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mt-2">
              <span class="font-weight-bold text-muted mr-2">真实姓名:</span>
              <span class="text-muted">{{
                currentUserAccountInfo.realName || "暂未补全"
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-2">
              <span class="font-weight-bold text-muted mr-2">联系电话:</span>
              <span class="text-muted">{{
                currentUserAccountInfo.phone || "暂未设置"
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-2">
              <span class="font-weight-bold text-muted mr-2">电子邮箱:</span>
              <a class="text-muted text-hover-primary">{{
                currentUserAccountInfo.email || "暂未设置"
              }}</a>
            </div>
          </div>
          <!--end::Contact-->

          <!--begin::Nav-->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
            <div class="navi-item mb-2">
              <a class="navi-link py-4" @click="setActiveTab" data-tab="account" data-toggle="tab" role="tab"
                aria-selected="false">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">账号信息</span>
              </a>
            </div>

            <div class="navi-item mb-2" v-if="currentUser.userType == 2">
              <a class="navi-link py-4" @click="setActiveTab" data-tab="merchant" data-toggle="tab" role="tab"
                aria-selected="false">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">商户信息</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a class="navi-link py-4" @click="setActiveTab" data-tab="password" data-toggle="tab" role="tab"
                aria-selected="false">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">更改密码</span>
              </a>
            </div>

            <div class="navi-item mb-2" v-if="currentUser.userType == 2">
              <a class="navi-link py-4" @click="setActiveTab" data-tab="payPassword" data-toggle="tab" role="tab"
                aria-selected="false">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Shopping/Credit-card.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">支付密码</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a class="navi-link py-4" @click="setActiveTab" data-tab="googleAuth" data-toggle="tab" role="tab"
                aria-selected="false">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Home/Timer.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">谷歌验证</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-container>
        <b-row v-if="currentTab=='account'">
          <b-col cols="12">
            <AccountInformation></AccountInformation>
          </b-col>
        </b-row>

        <b-row class="2" v-if="currentUser.userType == 2 && currentTab=='merchant'">
          <b-col cols="12">
            <MerchantInformation></MerchantInformation>
          </b-col>
        </b-row>

        <b-row v-if="currentTab=='password'">
          <b-col cols="12">
            <ChangePassword></ChangePassword>
          </b-col>
        </b-row>

        <b-row v-if="currentUser.userType == 2  && currentTab=='payPassword'">
          <b-col cols="12">
            <PayPassword></PayPassword>
          </b-col>

        </b-row>

        <b-row v-if="  currentTab=='googleAuth'">
          <b-col cols="12">
            <GoogleAuth></GoogleAuth>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
  import {
    SET_BREADCRUMB
  } from "@/core/services/store/breadcrumbs.module";
  import {
    mapGetters
  } from "vuex";
  import AccountInformation from "@/view/pages/common/profile/profile-comp/AccountInformation";
  import ChangePassword from "@/view/pages/common/profile/profile-comp/ChangePassword";
  import PayPassword from "@/view/pages/common/profile/profile-comp/PayPassword";

  import MerchantInformation from "@/view/pages/common/profile/profile-comp/MerchantInformation";
  import GoogleAuth from "@/view/pages/common/profile/profile-comp/GoogleAuth";


  export default {
    name: "custom-page",
    components: {
      AccountInformation,
      ChangePassword,
      PayPassword,
      MerchantInformation,
      GoogleAuth
    },
    data() {
      return {
        currentTab: "account",
        avatar: "media/users/default.jpg",
      };
    },
    created() {
      this.$store.dispatch("initUserAccountInfo");
    },
    methods: {
      /**
       * Set current active on click
       * @param event
       */
      setActiveTab(event) {
        let target = event.target;
        if (!event.target.classList.contains("navi-link")) {
          target = event.target.closest(".navi-link");
        }

        const tab = target.closest('[role="tablist"]');
        const links = tab.querySelectorAll(".navi-link");
        // remove active tab links
        for (let i = 0; i < links.length; i++) {
          links[i].classList.remove("active");
        }

        // set clicked tab index to bootstrap tab
        this.currentTab = target.getAttribute("data-tab");
        // set current active tab
        target.classList.add("active");
      },
    },
    computed: {
      ...mapGetters(["currentUserAccountInfo"]),
      ...mapGetters(["currentUser"]),
    },

    watch: {
      "$route.query.index": {
        handler(newVal, oldVal) {
          if (newVal != undefined && newVal != "") {
            this.currentTab = newVal;
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
<style>
  .nav.nav-tabs .nav-item {
    display: none;
  }
</style>