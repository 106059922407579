<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">绑定谷歌身份验证</h3>
      </div>

    </div>
    <!--end::Header-->

    <!--begin::Form-->
    <form class="form" id="kt_password_change_form" v-on:submit.prevent>
      <div class="card-body">
        <!--begin::Alert-->
        <div
          :class="['alert', 'alert-custom', 'fade', 'show', 'mb-10', { 'alert-light-success': alert.isSuccess == true }, { 'alert-light-danger': alert.isSuccess == false }]"
          role="alert" v-if="alert.show">
          <div class="alert-icon">
            <i class="icon-2l fas fa-check-circle"></i>
          </div>
          <div class="alert-text font-weight-bold">
            {{ alert.message }}
          </div>
          <div class="alert-close">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" v-on:click="alert.show = false">
              <span aria-hidden="true">
                <i class="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
        <!--end::Alert-->
        <div class="row">
          <div class="col-12" style="text-align:center!important">打开谷歌身份验证App，扫描下方二维码或手动输入下述秘钥添加令牌</div>
        </div>
        <div class="row mt-5">
          <div class="col-1"></div>
          <div class="col-4 border-right">
            <img :src="bindInfo.qrCode" />
          </div>

          <div class="col-5 ml-10 ">
            <div class="row">
              <div class="col-12"> <span style="color:#f64e60"><b>扫不了码？ 手动输入秘钥</b></span></div>
            </div>
            <div class="row mt-4">
              <div class="col-12"><b>账号:</b> <span class="ml-4">{{ bindInfo.account }}</span></div>
            </div>
            <div class="row  mt-4">
              <div class="col-12"><b>秘钥:</b> <span class="ml-4"> {{ bindInfo.googleKey }}</span></div>
            </div>

            <div class=" row mt-5">
              <div class="col-12"><b>验证码：</b>
                <b-form-input v-model="code" placeholder="输入谷歌身份验证器中的验证码" class="ml-2 w-75"
                  style="display:inline-block!important"></b-form-input>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <button type="submit" class="btn btn-success w-75" @click="save()" ref="bindBtn">
                  绑定
                </button>
              </div>
            </div>
          </div>

          <div class="col-1"></div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Vue from "vue";
import Qs from 'qs'
import {
  mapGetters
} from "vuex";
import {
  UPDATE_PASSWORD
} from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";

import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  data() {
    return {
      bindInfo: {

      },
      code: "",
      alert: {
        show: false,
        message: "",
        isSuccess: false,
      }
    };
  },
  mounted() {
    this.generateKey();
  },
  methods: {
    generateKey() {
      ApiService.get("/api/generate/google/key").then(({
        data
      }) => {
        if (data.success) {
          this.bindInfo = data.result;
        }
      })
    },
    save() {
      let that = this;
      if (this.code == undefined || this.code == null || this.code == "") {
        that.alert.message = "请输入谷歌验证码!";
        that.alert.isSuccess = false;
        that.alert.show = true;
        return;
      }

      let formModel = {
        googleKey: that.bindInfo.googleKey,
        code: that.code
      }
      const submitButton = that.$refs["bindBtn"];

      // set spinner to submit button
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      Vue.axios.post("/api/bind/google/key",
        Qs.stringify(formModel), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(({
          data
        }) => {
          that.alert.show = true;
          if (data.success) {
            that.alert.message = "绑定成功!";
            that.alert.isSuccess = true;
          } else {
            that.alert.message = data.message;
            that.alert.isSuccess = false;
          }
        }).catch(({
          response
        }) => {
          console.info("error:", response);
          that.alert.show = true;
          that.alert.message = data.err;
          that.alert.isSuccess = false;
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      // dummy delay
      setTimeout(() => {
        that.alert.show = false;
      }, 3000);
    },

  },
  computed: {}
};
</script>

<style scoped>
</style>