<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          账号信息
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"></span>
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
          保存
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          取消
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <!--begin::Alert-->
        <div
          :class="['alert','alert-custom','fade','show','mb-10',{'alert-light-success': alert.isSuccess==true},{'alert-light-danger': alert.isSuccess==false}]"
          role="alert" v-if="alert.show">
          <div class="alert-icon">
            <i class="icon-2l fas fa-check-circle"></i>
          </div>
          <div class="alert-text font-weight-bold">
            {{alert.message}}
          </div>
          <div class="alert-close">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" v-on:click="alert.show=false">
              <span aria-hidden="true">
                <i class="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
        <!--end::Alert-->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">用户信息</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left form-group-label">用户名</label>
          <div class="col-lg-9 col-xl-6">
            <input class="form-control form-control-lg form-control-solid " type="text" readonly disabled
              v-bind:value="formModel.userName" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left form-group-label">真实姓名</label>
          <div class="col-lg-9 col-xl-6">
            <input class="form-control form-control-lg form-control-solid" type="text" placeholder="真实姓名..."
              v-model="formModel.realName" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left form-group-label">注册时间</label>
          <div class="col-lg-9 col-xl-6">
            <input class="form-control form-control-lg form-control-solid" type="text"
              v-model="formModel.registerDate" />
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">联系信息</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left form-group-label">电话号码</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg form-control-solid" placeholder="电话号码..."
                v-model="formModel.phone" />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left form-group-label">电子邮箱</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg form-control-solid" placeholder="电子邮箱..."
                v-model="formModel.email" />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left form-group-label">微信号</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid ">
              <input type="text" class="form-control form-control-lg form-control-solid" placeholder="微信号..."
                v-model="formModel.wechat" />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left form-group-label">QQ号码</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid ">
              <input type="text" class="form-control form-control-lg form-control-solid" placeholder="QQ号码..."
                v-model="formModel.qq" />
            </div>
          </div>
        </div>
        
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  import {
    UPDATE_ACCOUNT_INFO
  } from "@/core/services/store/profile.module";

  export default {
    name: "AccountInformation",
    data() {
      return {
        formModel: {

        },
        alert: {
          show: false,
          message: "",
          isSuccess: false,
        }
      };
    },
    mounted() {},
    methods: {
      save() {
        let that = this;
        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        this.$store.dispatch(UPDATE_ACCOUNT_INFO, this.formModel).then((data) => {
          that.alert.show = true;
          if (data.success) {
            that.alert.message = "保存成功!";
            that.alert.isSuccess = true;
          } else {
            that.alert.message = data.message;
            that.alert.isSuccess = false;
          }
        }).catch(({
          err
        }) => {
          that.alert.show = true;
          that.alert.message = err;
          that.alert.isSuccess = false;
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        // dummy delay
        setTimeout(() => {
          that.alert.show = false;
        }, 3000);
      },
      cancel() {
        this.formModel = this.$store.getters.currentUserAccountInfo;
      },
    },
    computed: {
      ...mapGetters(["currentUserAccountInfo"]),
    },
    watch: {
      currentUserAccountInfo: {
        handler(newVal, oldVal) {
          this.formModel = newVal;
        },
        deep: true,
      }
    }

  };
</script>