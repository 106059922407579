<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">更改支付密码</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >设置支付密码</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          提交
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          取消
        </button>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Form-->
    <form class="form" id="kt_pay_password_form">
      <div class="card-body">
        <!--begin::Alert-->
        <div
          :class="[
            'alert',
            'alert-custom',
            'fade',
            'show',
            'mb-10',
            { 'alert-light-success': alert.isSuccess == true },
            { 'alert-light-danger': alert.isSuccess == false },
          ]"
          role="alert"
          v-if="alert.show"
        >
          <div class="alert-icon">
            <i class="icon-2l fas fa-check-circle"></i>
          </div>
          <div class="alert-text font-weight-bold">
            {{ alert.message }}
          </div>
          <div class="alert-close">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-on:click="alert.show = false"
            >
              <span aria-hidden="true">
                <i class="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
        <!--end::Alert-->
        <div class="form-group row" v-if="account.status != 2">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-alert form-group-label"
            >当前密码</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              value=""
              placeholder="当前密码..."
              name="current_password"
              ref="current_password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-alert form-group-label"
            >新密码</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="新密码..."
              name="newPassword"
              ref="new_password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-alert form-group-label"
            >确认密码</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="确认密码..."
              name="verifyPassword"
              ref="verify_password"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Vue from "vue";
import Qs from "qs";
import { mapGetters } from "vuex";
import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "PayPassword",
  data() {
    return {
      account: {},
      password: "",
      status: "",
      valid: true,
      alert: {
        show: false,
        message: "",
        isSuccess: false,
      },
    };
  },
  created() {
    this.getAccount();
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_pay_password_form");

    this.fv = formValidation(password_change_form, {
      fields: {
        newPassword: {
          validators: {
            notEmpty: {
              message: "新密码不能为空!",
            },
          },
        },
        verifyPassword: {
          validators: {
            notEmpty: {
              message: "请再次确认密码!",
            },
            identical: {
              compare: function () {
                return password_change_form.querySelector(
                  '[name="newPassword"]'
                ).value;
              },
              message: "两次密码不一致!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton(),
      },
    });
    if (this.account.status != 2) {
      this.fv.addField("currentPassword", {
        validators: {
          notEmpty: {
            message: "原密码不能为空!",
          },
        },
      });
    }
  },
  methods: {
    getAccount() {
      let that = this;
      ApiService.query("/api/merchant/account/query_by_id", {}).then(
        ({ data }) => {
          that.account = data.result;
          that.account.balance = data.result.balance / 100.0;
        }
      );
    },
    save() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          let currentPassword = "";
          if (that.account.status != 2) {
            currentPassword = that.$refs.current_password.value;
          }
          let newPassword = that.$refs.new_password.value;
          let formModel = {
            currentPassword: currentPassword,
            newPassword: newPassword,
          };

          const submitButton = that.$refs["kt_save_changes"];

          // set spinner to submit button
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          Vue.axios
            .post(
              "/api/merchant/change_pay_password",
              Qs.stringify(formModel),
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then(({ data }) => {
              that.alert.show = true;
              if (data.success) {
                that.alert.message = "保存成功!";
                that.alert.isSuccess = true;
              } else {
                that.alert.message = data.message;
                that.alert.isSuccess = false;
              }
              that.getAccount();
            })
            .catch(({ response }) => {
              console.info("error:", response);
              that.alert.show = true;
              that.alert.message = data.err;
              that.alert.isSuccess = false;
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          // dummy delay
          setTimeout(() => {
            that.alert.show = false;
          }, 3000);
        }
      });
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },
  },
  computed: {},
};
</script>

<style scoped></style>
